

















































import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserStatus, HelpTypes } from "@/helpers";

@Component
class CaregiverFullTable extends Vue {
  @Prop() caregiverItems!: [];
  @Prop() onCaregiverItemClick!: Function;

  caregiverItemsColumns = [
    {
      label: "Datum",
      field: "created",
      type: "Date",
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          "Afgelopen week",
          "Afgelopen maand",
          "Afgelopen jaar",
        ],
        filterFn: CaregiverFullTable.dateFilter,
      },
    },
    {
      label: "Status",
      field: "person.status",
      filterOptions: {
        enabled: true,
        filterDropdownItems: UserStatus,
      },
      sortable: false,
    },
    {
      label: "Voornaam",
      field: "person.firstName",
      type: "string",
      filterOptions: {
        enabled: true,
      },
    },
    {
      label: "Achternaam",
      field: "person.lastName",
      type: "string",
      filterOptions: {
        enabled: true,
      },
    },
    {
      label: "Postcode",
      field: "person.zipcode",
      filterOptions: {
        enabled: true, // enable filter for this column
      },
    },
    /*
    {
      label: 'Status',
      field: 'status',
      filterOptions: {
        enabled: true, // enable filter for this column
        filterDropdownItems: RequestStatus,
      },
    },
    */
    {
      label: "Soorthulp",
      field: "helptype",
      filterOptions: {
        enabled: true, // enable filter for this column
        filterDropdownItems: HelpTypes,
      },
      sortable: false,
    },
    {
      label: "Aangemaakt door",
      field: "user.displayName",
      filterOptions: {
        enabled: true, // enable filter for this column
      },
    },
    {
      label: "Aangepast door",
      field: "userUpdates.displayName",
      filterOptions: {
        enabled: true, // enable filter for this column
      },
    },
    {
      label: "Datum gewijzigd",
      field: "updated",
      type: "Date",
      filterOptions: {
        enabled: true,
        filterDropdownItems: [
          "Afgelopen week",
          "Afgelopen maand",
          "Afgelopen jaar",
        ],
        filterFn: CaregiverFullTable.dateFilter,
      },
    },
  ];

  constructor() {
    super();
    //this.caregiverItems = [];
    //this.onCaregiverItemClick = () => { };
  }

  data() {
    return {
      // caregiverItemsColumns: this.caregiverItemsColumns,
    };
  }

  static dateFilter(data: string, filterString: string) {
    const dt = moment(data);
    if (filterString === "Afgelopen week") {
      const weekDT = moment().subtract(7, "days");
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === "Afgelopen maand") {
      const weekDT = moment().subtract(1, "month");
      return dt.isSameOrAfter(weekDT);
    }
    if (filterString === "Afgelopen jaar") {
      const weekDT = moment().subtract(1, "year");
      return dt.isSameOrAfter(weekDT);
    }
  }
}

export default CaregiverFullTable;
