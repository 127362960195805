var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.caregiverItemsColumns,"search-options":{
    enabled: false,
    placeholder: 'Zoeken....',
  },"pagination-options":{
    enabled: true,
    mode: 'records',
    nextLabel: 'volgende',
    prevLabel: 'vorige',
    rowsPerPageLabel: 'Aantal per pagina',
    ofLabel: 'van',
    pageLabel: 'pagina', // for 'pages' mode
    allLabel: 'Alles',
  },"sort-options":{
    enabled: true,
    initialSortBy: { field: 'person.lastName', type: 'asc' },
  },"styleClass":"tableOne vgt-table table-striped table-hover","rows":_vm.caregiverItems},on:{"on-row-click":_vm.onCaregiverItemClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'helptype')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatHelpTypes")(props.formattedRow[props.column.field])))])]):(props.column.field === 'updated')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.updated))+" ")]):(props.column.field === 'created')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.row.created))+" ")]):(props.column.field === 'status')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRequestStatus")(props.row.status))+" ")]):(props.column.field === 'person.status')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatUserStatus")(props.row.person.status))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }