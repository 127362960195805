<template>
  <div class="main-content">
    <b-modal
      id="confirm-remove-participant"
      hide-backdrop
      content-class="shadow"
      title="Verwijderen"
    >
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click.prevent="removeParticipant"
        >
          Ja
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="outline-secondary"
          @click.prevent="cancel()"
        >
          Nee
        </b-button>
      </template>
      <p class="my-4">Weet u zeker dat u deze deelnemer wilt verwijderen?</p>
    </b-modal>
    <div class="card user-profile o-hidden mb-30">
      <div class="header-cover" />
      <div class="user-info">
        <img
          class="profile-picture avatar-lg mb-2"
          src="@/assets/images/default.png"
          alt=""
        />
        <p class="m-0 text-24">Hulpaanbod</p>
      </div>
      <b-card class="wrapper">
        <caregiver-full-table
          :caregiver-items="caregiverItems"
          :on-caregiver-item-click="onCaregiverItemClick"
        />
        <b-modal
          id="caregiver-item-modal"
          size="xl"
          :ok-title="okLabel"
          :cancel-title="cancelLabel"
          title="Hulpaanbod"
          @ok="saveCaregiverItem"
        >
          <template v-slot:modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="success" @click.prevent="ok()">
              Opslaan
            </b-button>
            <b-button
              v-if="caregiverItem._id"
              size="sm"
              variant="danger"
              @click="showConfirmRemoveCaregiverItem"
            >
              Verwijderen
            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button
              size="sm"
              variant="outline-secondary"
              @click.prevent="cancel()"
            >
              Sluiten
            </b-button>
          </template>
          <b-card>
            <b-modal
              id="confirm-remove-caregiver-item"
              hide-backdrop
              content-class="shadow"
              title="Verwijderen"
            >
              <template v-slot:modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="removeCaregiverItem"
                >
                  Ja
                </b-button>
                <!-- Button with custom close trigger value -->
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click.prevent="cancel()"
                >
                  Nee
                </b-button>
              </template>
              <p class="my-4">Weet u zeker dat u dit item wilt verwijderen?</p>
            </b-modal>
            <caregiver-item :caregiver-item="caregiverItem" />
          </b-card>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>
<script>
import { participantsService } from "@/services";
import CaregiverFullTable from "@/components/caregiver-full-table.vue";
import CaregiverItem from "@/components/caregiver-item.vue";

export default {
  components: {
    CaregiverFullTable,
    CaregiverItem,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Deelnemers",
  },
  data() {
    return {
      participant: {},
      caregiverItems: [],
      caregiverItem: {},
      careRecipientItems: [],
      careRecipientItem: {},
      okLabel: "Opslaan",
      cancelLabel: "Sluiten",
      matchItems: [],
    };
  },
  computed: {
    hulpvraagTitle() {
      return `Hulpvraag (${
        this.careRecipientItems ? this.careRecipientItems.length : 0
      })`;
    },
    hulpaanbodTitle() {
      return `Hulpaanbod (${
        this.caregiverItems ? this.caregiverItems.length : 0
      })`;
    },
    matchesTitle() {
      return `Matches (${this.matchItems ? this.matchItems.length : 0})`;
    },
  },
  created() {
    participantsService.getAllCaregivers().then((caregiverItems) => {
      this.caregiverItems = caregiverItems || [];
    });
  },
  methods: {
    saveCaregiverItem() {
      this.caregiverItem.helptype = [this.caregiverItem.helptypeAlt];
      participantsService
        .saveCaregiverItem(this.caregiverItem)
        .then((res) => {
          console.debug(res);
          this.caregiverItem = {};
          participantsService.getAllCaregivers().then((caregiverItems) => {
            this.caregiverItems = caregiverItems;
          });
          this.$bvToast.toast("Opgeslagen", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    closeCaregiverItem() {
      this.caregiverItem = {};
    },
    newCaregiverItem() {
      this.caregiverItem = {};
      this.caregiverItem.isNew = true;
      this.caregiverItem.person = this.participant._id;
      this.$bvModal.show("caregiver-item-modal");
    },
    showConfirmRemoveCaregiverItem() {
      this.$bvModal.show("confirm-remove-caregiver-item");
    },
    removeCaregiverItem() {
      participantsService
        .removeCaregiverItem(this.caregiverItem)
        .then((res) => {
          participantsService.getAllCaregivers().then((caregiverItems) => {
            this.caregiverItems = caregiverItems;
          });
          this.$bvModal.hide("confirm-remove-caregiver-item");
          this.$bvModal.hide("caregiver-item-modal");
          this.$bvToast.toast("Verwijderd", {
            title: "Melding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "info",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: "Foutmelding",
            autoHideDelay: 2500,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    onCaregiverItemClick(data) {
      this.caregiverItem = JSON.parse(JSON.stringify(data.row));
      this.caregiverItem.helptypeAlt = this.caregiverItem.helptype[0];
      this.$bvModal.show("caregiver-item-modal");
    },
  },
};
</script>
<style></style>
